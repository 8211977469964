import React from "react";
import { Text, StyleSheet, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { getDefaultStyles } from "../PdfStyles";
import { FirstCell } from "./FirstCell";
import { Cell } from "./Cell";
import { Address } from "./Address";
import { ComponentProps } from "../PdfPage";

function getSpecificStyles(show: boolean) {
  return StyleSheet.create({});
}

function getLabels(t: any) {
  return {
    header: t("Company information"),
    organizationNumber: t("Organizational number"),
    companyName: t("Company name"),
    businessType: t("Type of business"),
    phoneNumber: t("Phone number"),
    description: t("Business model"),
    legalForm: t("Legal form"),
    taxRegistrationId: t("VAT number"),
    typeOfProducts: t("Products or Services the company accepts payments for"),
  };
}

export const CompanyInformation: React.FunctionComponent<ComponentProps> = ({
  language,
  productType,
  show,
  config,
}) => {
  const { t } = useTranslation();
  const labels = getLabels(t);
  const styles = {
    ...getDefaultStyles(show),
    ...getSpecificStyles(show),
  };

  return (
    <>
      <View style={styles.sectionHeader}>
        <Text>{labels.header}</Text>
      </View>

      <View style={styles.split}>
        <View style={styles.column}>
          <FirstCell label={labels.companyName} show={show}>
            Ascending Averageness
          </FirstCell>
        </View>
        <View style={styles.column}>
          <Cell label={labels.organizationNumber} show={show}>
            9291900778
          </Cell>
        </View>
      </View>

      <View style={styles.split}>
        <View style={styles.column}>
          <FirstCell label={labels.legalForm} show={show}>
            Limited
          </FirstCell>
        </View>
        <View style={styles.column}>
          <Cell label={labels.taxRegistrationId} show={show}>
            SE559264194701
          </Cell>
        </View>
      </View>

      <View style={styles.split}>
        <View style={styles.largeColumn}>
          <FirstCell label={labels.businessType} show={show}>
            General contractors, construction services
          </FirstCell>
        </View>
        <View style={styles.smallColumn}>
          <Cell label={labels.phoneNumber} show={show}>
            +46 702 222 222
          </Cell>
        </View>
      </View>

      <View>
        <FirstCell label={labels.description} show={show}>
          Betalningar livsmedelsbutik
        </FirstCell>
      </View>

      <View>
        <FirstCell label={labels.typeOfProducts} show={show}>
          Shoes, big ones, small ones - we've got 'em
        </FirstCell>
      </View>

      <Address
        show={show}
        language={language}
        productType={productType}
        config={config}
      />
    </>
  );
};
