import React, { useCallback, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AddressWithSearch } from "../../../../../components/address/AddressWithSearch";
import { Form, FormContainer } from "../../../../../components/form/Form";
import { hasRealErrors } from "../../../../../components/form/FormHelpers";
import { TextInput } from "../../../../../components/form/TextInput";
import { MaxLengthValidator } from "../../../../../components/form/validators/MaxLengthValidator";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { T } from "../../../../../components/translation/T";
import {
  Address as AddressType,
  ProductType,
  Store as StoreEntity,
  TerminalIntegrated,
  TerminalType,
} from "../../../../../data/models/ContractTypes";
import { storeQueue } from "../../../../../data/queues/dataQueues";
import "../Store.scss";
import {
  contractState,
  disabledState,
} from "../../../../../state/contractState";
import { useContractId } from "../../../../../hooks/useContractId";
import { dataStores } from "../../../../../data/dataStores";
import { MinLengthValidator } from "../../../../../components/form/validators/MinLengthValidator";
import isEqual from "lodash.isequal";
import { PhoneNumberCountry } from "../../../../../components/contact/PhoneNumberCountry";
import {
  MIN_MOBILE_PHONE_CHARS,
  MAX_MOBILE_PHONE_CHARS,
} from "../../../../../components/contact/Contact";
import { Terminals } from "../terminals/Terminals";
import { HiddenInput } from "../../../../../components/form/HiddenInput";
import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import styles from "./StoreForm.module.scss";
import {
  replacePhoneNumberBeginning,
  sanitizePhoneNumber,
} from "../../../../../components/form/validators/PhoneToCountryCode";
import { Products } from "../../../../../components/products/Products";
import { Trans, useTranslation } from "react-i18next";
import { InfoBox } from "../../../../../components/boxes/InfoBox";
import {
  contractErrorState,
  contractSaveState,
} from "../../../../../state/contractSaveState";
import { ONGOING_RESPONSE } from "../../../../../data/models/CommonTypes";
import { ServerError } from "../../../../../network/API";
import { UNAUTHORIZED_STATUS_CODE } from "../../../../../data/data";
import { ContractEditError } from "../../ContractEditError";
import { ContractSaveError, handleError } from "../../ContractPage";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { Status } from "../../../../../data/types";
import { Country } from "../../../../../i18n";

interface Props {
  formName: string;
  setStore: React.Dispatch<React.SetStateAction<StoreEntity>>;
  store: StoreEntity;
  formContainer: React.MutableRefObject<FormContainer | undefined>;
}

const countryToPhonePrefixes: Record<Country, string> = {
  [Country.SWEDEN]: "+46",
  [Country.DENMARK]: "+45",
  [Country.FINLAND]: "+358",
  [Country.NORWAY]: "+47",
};

function shouldSaveStore(updated: StoreEntity, lastSaved: StoreEntity) {
  if (isEqual(updated, lastSaved)) {
    return false;
  }

  if (!updated.commercialName) {
    return false;
  }

  if (!updated.doingBusinessAs) {
    return false;
  }

  if (!updated.address) {
    return false;
  }

  if (!updated.address.street) {
    return false;
  }

  // don't save if no terminals have been added
  // if (
  //   (updated[TerminalType.WIRELESS_STANDALONE]?.terminals ?? 0) +
  //     (updated[TerminalType.WIRELESS_INTEGRATED]?.terminals ?? 0) +
  //     (updated[TerminalType.WIRED_INTEGRATED]?.terminals ?? 0) ===
  //   0
  // ) {
  //   return false;
  // }

  if (
    (updated[TerminalType.WIRED_INTEGRATED]?.terminals ?? 0) > 0 &&
    (!updated[TerminalType.WIRED_INTEGRATED]?.terminalModelId ||
      !updated[TerminalType.WIRED_INTEGRATED]?.integrationId)
  ) {
    return false;
  }

  if (
    (updated[TerminalType.WIRELESS_INTEGRATED]?.terminals ?? 0) > 0 &&
    (!updated[TerminalType.WIRELESS_INTEGRATED]?.terminalModelId ||
      !updated[TerminalType.WIRELESS_INTEGRATED]?.integrationId)
  ) {
    return false;
  }

  return true;
}

export const StoreForm: React.FunctionComponent<Props> = ({
  store,
  setStore,
  formContainer,
  formName,
}) => {
  const contractId = useContractId();
  const scrollToRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const disabled = useRecoilValue(disabledState);
  const contract = useRecoilValue(contractState);
  const setDataError = useSetRecoilState(contractErrorState);
  const setDataSaved = useSetRecoilState(contractSaveState);
  const onClose = useCallback(() => setError(null), []);
  const { t } = useTranslation();
  const { country } = contract;

  const [error, setError] = useState<ContractSaveError | null>(null);
  const lastEditedField = useRef<string>("");
  const latestSaved = useRef<StoreEntity>(window.structuredClone(store));
  const latestErroneousSaved = useRef<StoreEntity | null>(null);
  const [displayableError, setDisplayableError] = useState<
    string | undefined
  >();

  const {
    mutate: trySave,
    isError,
    isPending,
  } = useMutation({
    mutationFn: (store: StoreEntity) => {
      setDisplayableError(undefined);
      if (isEqual(store, latestSaved.current)) {
        return Promise.resolve(store);
      }
      return storeQueue.saveData(contractId, store);
    },
    onSuccess: (response) => {
      setStore((prev) => ({
        ...prev,
        cas: (response as StoreEntity).cas,
        storeId: (response as StoreEntity).storeId,
      }));

      latestSaved.current = store;
      setDataSaved((dataSaved) =>
        dataSaved.concat({
          date: new Date(),
        })
      );
    },
    onError: (err: unknown, updated: StoreEntity, context) => {
      if (err === ONGOING_RESPONSE) {
        return;
      }

      if (
        (err as ServerError<StoreEntity>).status === UNAUTHORIZED_STATUS_CODE
      ) {
        return;
      }

      const untypedError = err as any;

      if (typeof untypedError?.data === "string") {
        setDisplayableError(untypedError?.data);
      } else if (typeof untypedError?.data?.message === "string") {
        setDisplayableError(untypedError?.data?.message);
      } else {
        setDisplayableError(undefined);
      }

      latestErroneousSaved.current = store;
      handleError(err as ServerError<StoreEntity>, setError);
      setStore(latestSaved.current);
      setDataError((dataErrors) =>
        dataErrors.concat({
          date: new Date(),
        })
      );
    },
  });

  const { data: terminalOptions } = useSuspenseQuery(
    dataStores.fetchTerminalOptions(contractId)
  );

  const saveStore = useCallback(
    (store: StoreEntity) => {
      setStore(store);

      const copy = {
        ...store,
        phoneNumber: sanitizePhoneNumber(store.phoneNumber),
      };

      if (!shouldSaveStore(copy, latestSaved.current)) {
        return;
      }

      trySave(copy);
    },
    [trySave, setStore]
  );

  const onChange = useCallback(
    (value: string, name: string) => {
      lastEditedField.current = name;

      setStore((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    },
    [setStore]
  );

  const setAddress = useCallback(
    (address: AddressType) => {
      setStore((prev) => {
        const update = {
          ...prev,
          address,
        };

        if (!shouldSaveStore(update, latestSaved.current)) {
          return update;
        }

        trySave(update);
        return update;
      });
    },
    [trySave, setStore]
  );

  const validators = useMemo(() => {
    return [
      new RequiredValidator("Phone number is required"),
      // new PhoneCountryCodeValidator(
      //   "Phone number must start with a country code e.g +61..."
      // ),
      // new RegexValidator(PHONE_REGEX, "Phone contains invalid characters"),
      new MinLengthValidator(
        MIN_MOBILE_PHONE_CHARS,
        `Mobile phone must be at least ${MIN_MOBILE_PHONE_CHARS} characters`
      ),
      new MaxLengthValidator(
        MAX_MOBILE_PHONE_CHARS,
        `Mobile phone must be less than ${MAX_MOBILE_PHONE_CHARS} characters`
      ),
    ];
  }, []);

  const onPaste = useCallback(
    (value: string) => {
      let phoneNumber = sanitizePhoneNumber(value);
      phoneNumber = replacePhoneNumberBeginning(country, phoneNumber);
      onChange(phoneNumber, "phoneNumber");
    },
    [country, onChange]
  );

  const retrySave = useCallback(() => {
    if (!latestErroneousSaved.current) {
      return;
    }

    setError(null);

    setTimeout(() => {
      if (!latestErroneousSaved.current) {
        return;
      }

      trySave(latestErroneousSaved.current);
    }, 500);
  }, [trySave]);

  const hiddenValue = useMemo(() => {
    const numberOfTerminals = getNumberOfTerminals(store);

    return numberOfTerminals === 0 &&
      contract.productType !== ProductType.ACCEPTANCE_INSTORE
      ? undefined
      : true;
  }, [store, contract.productType]);

  return (
    <div className={styles.root} ref={scrollToRef}>
      <ContractEditError
        error={error}
        displayableError={displayableError}
        retry={latestErroneousSaved.current ? retrySave : undefined}
        onClose={onClose}
      />
      <Form
        formContainer={formContainer}
        ref={formRef}
        onSaveTrigger={(_, form) => {
          const realErrors = hasRealErrors(form);

          let phoneNumber = store.phoneNumber;
          if (lastEditedField.current === "phoneNumber") {
            let phone = sanitizePhoneNumber(phoneNumber);
            phone = replacePhoneNumberBeginning(country, phone);
            phoneNumber = phone;
            onChange(phone, "phoneNumber");
          }

          if (realErrors) {
            return;
          }

          const copy = {
            ...store,
            phoneNumber,
          };

          if (!shouldSaveStore(copy, latestSaved.current)) {
            return;
          }

          trySave(copy);
        }}
        name={formName}
      >
        <div className={styles.form}>
          <div className={styles.storeInformation}>
            <div className={styles.title}>
              <T>Store information</T>
            </div>

            <div className="tablet-columns">
              <div>
                <TextInput
                  onChange={onChange}
                  name="commercialName"
                  value={store.commercialName}
                  label="Store name"
                  validators={[
                    new RequiredValidator("Commercial name is required"),
                  ]}
                  disabled={disabled}
                />
              </div>

              <div>
                <TextInput
                  onChange={onChange}
                  name="phoneNumber"
                  onPaste={onPaste}
                  value={store.phoneNumber}
                  label="Store phone number"
                  validators={validators}
                  hint={`Must contain country code e.g. ${
                    countryToPhonePrefixes[country] ||
                    countryToPhonePrefixes[Country.SWEDEN]
                  }`}
                  placeholder={`E.g.   ${
                    countryToPhonePrefixes[country] ||
                    countryToPhonePrefixes[Country.SWEDEN]
                  } 709 111 111`}
                  message={
                    <PhoneNumberCountry phoneNumber={store.phoneNumber} />
                  }
                  disabled={disabled}
                />
              </div>
            </div>
            <div className={cx(styles.highlight)}>
              <Trans t={t}>
                <b>Doing business as</b> is the name of the business as it is
                known in the local area
              </Trans>
              <div className={cx("text-small", "text-passive")}>
                <T>Mastercard has an upper limit of 22 characters.</T>
              </div>

              <div className={cx("tablet-columns", "m-top-20")}>
                <div>
                  <TextInput
                    onChange={onChange}
                    name="doingBusinessAs"
                    value={store.doingBusinessAs}
                    label="Doing business as"
                    hint="Max 22 characters"
                    validators={[
                      new RequiredValidator("Doing business as is required"),
                      new MinLengthValidator(
                        2,
                        "Doing business as must include at least two characters"
                      ),
                      new MaxLengthValidator(
                        22,
                        "Doing business as cannot be longer than 22 characters"
                      ),
                    ]}
                    disabled={disabled}
                  />
                </div>

                <div className="text-right">
                  <Button
                    className={cx(styles.copyButton)}
                    status={
                      disabled || !store.commercialName
                        ? Status.DISABLED
                        : Status.DEFAULT
                    }
                    onClick={() => {
                      if (!store.commercialName) {
                        return;
                      }

                      onChange(
                        store.commercialName.substring(0, 22).trim(),
                        "doingBusinessAs"
                      );
                    }}
                  >
                    <Trans t={t}>
                      Copy from &nbsp;<i>Store name</i>
                    </Trans>
                  </Button>
                </div>
              </div>
              {/* <hr />

              <Trans t={t}>
                We also need to collect a <b>phone number</b> to customer
                service.
              </Trans>

              <div className={cx("tablet-columns", "m-top-20")}>
                <div>
                  <TextInput
                    onChange={onChange}
                    name="supportPhoneNumber"
                    value={store.supportPhoneNumber}
                    label="Customer service Phone Number"
                    disabled={disabled}
                  />
                </div>
                <div className="text-right">
                  <Button
                    className={cx(styles.copyButton)}
                    status={
                      disabled || !store.phoneNumber
                        ? Status.DISABLED
                        : Status.DEFAULT
                    }
                    onClick={() => {
                      if (!store.phoneNumber) {
                        return;
                      }

                      onChange(store.phoneNumber, "supportPhoneNumber");
                    }}
                  >
                    <Trans t={t}>
                      Copy from &nbsp;<i>Store phone number</i>
                    </Trans>
                  </Button>
                </div>
              </div> */}
            </div>

            <AddressWithSearch
              address={
                store.address || {
                  street: "",
                  postalCode: "",
                  city: "",
                  countryCode: "",
                }
              }
              onChange={setAddress}
              addressRequiredFields={{
                street: true,
                city: true,
                postalCode: true,
                countryCode: true,
              }}
              showCountryCode
              disabled={disabled}
            />
          </div>
        </div>

        <div
          className={cx(styles.wrapper, {
            hide: contract.productType === ProductType.ACCEPTANCE_INSTORE,
          })}
        >
          <Products productType={contract.productType}>
            <Products.Product products={[ProductType.ACCEPTANCE_INSTORE]}>
              <div className={styles.acqWarning}>
                <InfoBox relative>
                  <Trans t={t}>
                    This is a <b>acquiring product</b>. You might, however, add
                    additional terminals.
                  </Trans>
                </InfoBox>
              </div>
            </Products.Product>
          </Products>

          <h5>
            <T>Terminals</T>
          </h5>

          <Terminals
            store={store}
            saveStore={saveStore}
            terminalOptions={terminalOptions}
            isSaving={isPending}
            formRef={formRef}
            terminalType={TerminalType.WIRELESS_STANDALONE}
            isError={isError}
          />

          <Terminals
            store={store}
            saveStore={saveStore}
            terminalOptions={terminalOptions}
            isSaving={isPending}
            formRef={formRef}
            terminalType={TerminalType.WIRELESS_INTEGRATED}
            isError={isError}
          />

          <Terminals
            store={store}
            saveStore={saveStore}
            terminalOptions={terminalOptions}
            isSaving={isPending}
            formRef={formRef}
            terminalType={TerminalType.WIRED_INTEGRATED}
            isError={isError}
          />

          <Terminals
            store={store}
            saveStore={saveStore}
            terminalOptions={terminalOptions}
            isSaving={isPending}
            formRef={formRef}
            terminalType={TerminalType.WIRELESS_STANDALONE_ANDROID}
            isError={isError}
          />

          <Terminals
            store={store}
            saveStore={saveStore}
            terminalOptions={terminalOptions}
            isSaving={isPending}
            formRef={formRef}
            terminalType={TerminalType.WIRELESS_INTEGRATED_ANDROID}
            isError={isError}
          />

          <Terminals
            store={store}
            saveStore={saveStore}
            terminalOptions={terminalOptions}
            isSaving={isPending}
            formRef={formRef}
            terminalType={TerminalType.WIRED_INTEGRATED_ANDROID}
            isError={isError}
          />

          <HiddenInput
            label=""
            value={hiddenValue}
            scrollToRef={formRef}
            validators={[
              new RequiredValidator("You need to select at least one terminal"),
            ]}
          />
        </div>
      </Form>
    </div>
  );
};

function getNumberOfTerminals(store: StoreEntity) {
  let numberOfTerminals = 0;
  Object.values(TerminalType).forEach((type) => {
    const terminalObject = store[
      type as keyof StoreEntity
    ] as TerminalIntegrated;

    numberOfTerminals = numberOfTerminals + (terminalObject?.terminals ?? 0);
  });

  return numberOfTerminals;
}
