import { useCallback, useState } from "react";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { useMultiForm } from "../../../components/form/MultiFormContext";
import { Button } from "../../../components/interactions/Buttons/Button";
import { T } from "../../../components/translation/T";
import { dataContracts } from "../../../data/dataContracts";
import { ContractStatus } from "../../../data/models/ContractTypes";
import { Status } from "../../../data/types";
import { useContractEditStatus } from "../../../hooks/useContractEditStatus";
import { contractState, notesState } from "../../../state/contractState";
import { REVIEW_ROUTE } from "../Review/ReviewPage";
import { NewOverlay } from "../../../components/overlay/NewOverlay";
import { useQueryClient } from "@tanstack/react-query";
import { dataStores } from "../../../data/dataStores";
import { ConfirmStores } from "./components/ConfirmStores";
import { ConfirmPrimary } from "./components/ConfirmPrimary";
import { ConfirmProduct } from "./components/ConfirmProduct";
import { Notes } from "./notes/Notes";
import { useContractId } from "../../../hooks/useContractId";

const COMPLETED_CONTRACT_PAGE = "/TODO";

export function SendForConfirmation() {
  const contractId = useContractId();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [contract, setContract] = useRecoilState(contractState);
  const notes = useRecoilValue(notesState);
  const inputStatus = useContractEditStatus(status);
  const multiForm = useMultiForm();
  const [open, setOpen] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const onOpen = useCallback(() => {
    if (!multiForm || multiForm.isInvalid) {
      multiForm?.forceValidation();
      return;
    }

    queryClient.invalidateQueries({
      queryKey: dataStores.getStoresKey(contract.contractId),
    });

    setOpen(true);
  }, [multiForm, queryClient, contract.contractId]);

  const onClose = useCallback(() => setOpen(false), []);

  const navigate = useNavigate();

  const send = useCallback(() => {
    setStatus(Status.PENDING);

    dataContracts
      .finalizeAndSendForConfirmation(contract.contractId, notes)
      .then(() => {
        setTimeout(() => {
          dataContracts
            .loadContract(contractId)
            .then((contractResponse) => {
              queryClient.setQueryData(
                dataContracts.getContractsKey(contractId),
                contractResponse
              );

              setContract(contractResponse);
              setStatus(Status.SUCCESS);
              navigate(
                generatePath(REVIEW_ROUTE, { contractId: contract.contractId })
              );
            })
            .catch(() => {
              // Okay, we failed to refresh the contract. It's okay anyway.
              setStatus(Status.SUCCESS);
              navigate(
                generatePath(REVIEW_ROUTE, { contractId: contract.contractId })
              );
            });
        }, 500);
      })
      .catch((err) => {
        setStatus(Status.ERROR);

        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, 4000);
      });
  }, [
    contract.contractId,
    queryClient,
    contractId,
    setContract,
    notes,
    navigate,
  ]);

  if (
    contract.status === ContractStatus.MERCHANT_INPUT ||
    contract.status === ContractStatus.MERCHANT_SIGNING ||
    contract.status === ContractStatus.SALES_CONFIRMATION
  ) {
    return (
      <div className="send-for-confirmation">
        <Link
          className="as-button block"
          to={generatePath(REVIEW_ROUTE, { contractId: contract.contractId })}
        >
          <T>To confirmation page</T>
        </Link>
      </div>
    );
  }

  if (contract.status === ContractStatus.COMPLETE) {
    return (
      <div className="send-for-confirmation">
        <Link
          className="as-button block"
          to={generatePath(COMPLETED_CONTRACT_PAGE, {
            id: contract.contractId,
          })}
        >
          <T>To onboarding page</T>
        </Link>
      </div>
    );
  }

  return (
    <div className="send-for-confirmation">
      <NewOverlay
        open={open}
        onClose={onClose}
        className="overlay-confirm"
        width={600}
      >
        <ConfirmProduct />
        <ConfirmPrimary />
        <ConfirmStores />

        <div className="m-top-40">
          <Notes disabled={status !== Status.DEFAULT} />
        </div>

        <Button
          type="button"
          status={inputStatus}
          className="m-top-30"
          block
          onClick={send}
        >
          <T>Yes, all good</T>
        </Button>
      </NewOverlay>
      <Button type="button" block status={inputStatus} onClick={onOpen}>
        <T>Send for confirmation</T>
      </Button>
    </div>
  );
}
