import React from "react";
import { Text, StyleSheet, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { getDefaultStyles } from "../PdfStyles";
import { FirstCell } from "./FirstCell";
import { Cell } from "./Cell";
import { ComponentProps } from "../PdfPage";

function getSpecificStyles(show: boolean) {
  return StyleSheet.create({});
}

function getLabels(t: any) {
  return {
    header: t("Contact details"),
    name: t("Name"),
    phone: t("Phone number"),
    email: t("Email"),
  };
}

export const PrimaryContact: React.FunctionComponent<ComponentProps> = ({
  language,
  show = true,
}) => {
  const { t } = useTranslation();
  const labels = getLabels(t);
  const styles = {
    ...getDefaultStyles(show),
    ...getSpecificStyles(show),
  };

  return (
    <>
      <View style={styles.sectionHeader}>
        <Text>{labels.header}</Text>
      </View>

      <View style={styles.split}>
        <View style={styles.largeColumn}>
          <FirstCell label={labels.name} show={show}>
            Hannibal Barca
          </FirstCell>
        </View>
        <View style={styles.smallColumn}>
          <Cell label={labels.phone} show={show}>
            +46 702222222
          </Cell>
        </View>
      </View>

      <View>
        <FirstCell label={labels.email} show={show}>
          hannibal.barca@gmail.com
        </FirstCell>
      </View>
    </>
  );
};
