import { atom, selector } from "recoil";
import {
  Associate,
  AssociateRole,
  IdType,
  Owner,
} from "../data/models/AssociateTypes";
import { AssociateId, Cas, DEFAULT_CAS } from "../data/models/CommonTypes";

const DUMMY_OWNER_ID = "aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee" as AssociateId;

export const defaultIdentity = {
  cas: DEFAULT_CAS as Cas,
  idType: IdType.PASSPORT,
};

export const defaultOwner: Owner = {
  capitalStake: undefined, //0.00 - 100.00
  votingRightsStake: undefined, //0.00 - 100.00
};

export const defaultAssociate = {
  associateId: DUMMY_OWNER_ID,
  cas: DEFAULT_CAS as Cas,
  roles: [],
  address: {},
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
};

export function checkIdExpiry(associates: Associate[]) {
  return associates;
  // return associates.map((associate) => {
  //   if (!associate.identity) {
  //     return associate;
  //   }

  //   if (!associate.identity.idExpiry) {
  //     return associate;
  //   }

  //   if (associate.identity.idExpiry === NO_EXPIRY_DATE) {
  //     return {
  //       ...associate,
  //       identity: { ...associate.identity, noExpiry: true },
  //     };
  //   }

  //   return associate;
  // });
}

export const contractAssociateState = atom<Associate[]>({
  key: "contract-associate-state",
  default: [],
});

export const bankAccountOwnerSelector = selector({
  key: "bank-account-owner-selector",
  get: ({ get }) => {
    return get(contractAssociateState).filter(
      (item) => item.roles.indexOf(AssociateRole.ACCOUNT_HOLDER) > -1
    )[0];
  },
});

export const primaryContactSelector = selector({
  key: "primary-contact-selector",
  get: ({ get }) => {
    return get(contractAssociateState).filter(
      (item) => item.roles.indexOf(AssociateRole.PRIMARY_CONTACT) > -1
    )[0];
  },
});

export const sortedAssociatesSelector = selector({
  key: "sorted-associates-selector",
  get: ({ get }) => {
    const associates = get(contractAssociateState);
    return [...associates].sort((a, b) => {
      const aDate = new Date(a.created as string).getTime();
      const bDate = new Date(b.created as string).getTime();
      if (aDate < bDate) {
        return -1;
      }
      if (aDate > bDate) {
        return 1;
      }
      return a.associateId.localeCompare(b.associateId);
    });
  },
});

export const businessAssociatesSelector = selector({
  key: "business-associates-selector",
  get: ({ get }) => {
    return get(sortedAssociatesSelector).filter(
      (item) =>
        item.roles.indexOf(AssociateRole.BENEFICIAL_OWNER) > -1 ||
        item.roles.indexOf(AssociateRole.SIGNATORY) > -1 ||
        item.roles.indexOf(AssociateRole.ACCOUNT_HOLDER) > -1
    );
  },
});

export function isOwner(associate: Associate) {
  return associate.roles.indexOf(AssociateRole.BENEFICIAL_OWNER) > -1;
}

export function isSignee(associate: Associate) {
  return associate.roles.indexOf(AssociateRole.SELECTED_SIGNATORY) > -1;
}

export function hasLogin(associate: Associate) {
  return associate.roles.indexOf(AssociateRole.PORTAL_LOGIN) > -1;
}

export function isPrimary(associate: Associate) {
  return associate.roles.indexOf(AssociateRole.PRIMARY_CONTACT) > -1;
}

export function isAccountHolder(associate: Associate) {
  return associate.roles.indexOf(AssociateRole.ACCOUNT_HOLDER) > -1;
}
