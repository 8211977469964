import cx from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Beacon } from "../../../../../components/beacon/Beacon";
import { Edit } from "../../../../../components/icons/Edit";
import { Visibility } from "../../../../../components/icons/Visibility";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { NewOverlay } from "../../../../../components/overlay/NewOverlay";
import {
  BankAccount,
  BankAccountSource,
  Contract,
  ContractStatus,
  Validity,
} from "../../../../../data/models/ContractTypes";
import { Status } from "../../../../../data/types";
import { ConfirmBank } from "../ConfirmBank/ConfirmBank";
import { TIMELINE_BANK_ACCOUNT } from "../Timeline";
import { T } from "../../../../../components/translation/T";
import { ContractId } from "../../../../../data/models/CommonTypes";
import { Error } from "../../../../../components/icons/Error";

interface Props {
  active: boolean;
  contract: Contract;
  bankAccount?: BankAccount;
  contractId: ContractId;
}

function getConfirmationText(bankAccount: BankAccount) {
  if (bankAccount.source === BankAccountSource.OFFICIAL_REGISTRY) {
    return <T>Automatically confirmed</T>;
  }

  if (bankAccount.source === BankAccountSource.PSD_LOOKUP) {
    return <T>Automatically confirmed</T>;
  }

  if (bankAccount.source === BankAccountSource.BANK_STATEMENT) {
    return <T>Sales confirmation</T>;
  }

  if (bankAccount.signedOff) {
    return <T>Automatically confirmed</T>;
  }

  if (!!bankAccount.rejected) {
    return <T>Rejected bank account</T>;
  }

  return <T>Awaiting confirmation</T>;
}

function getLabelAndStatus(bankAccount: BankAccount) {
  if (!bankAccount.source) {
    return {
      label: "Added bank account",
      status: Validity.MISSING,
    };
  }

  if (bankAccount.source === BankAccountSource.OFFICIAL_REGISTRY) {
    return {
      label: "Verified by registry",
      status: Validity.VALID,
    };
  }

  if (bankAccount.source === BankAccountSource.PSD_LOOKUP) {
    return {
      label: "Added by merchant through Klarna",
      status: Validity.VALID,
    };
  }

  if (bankAccount.source === BankAccountSource.BANKGIRO) {
    return {
      label: "Added by merchant through Bankgiro",
      status: Validity.VALID,
    };
  }

  return {
    label: "Merchant uploaded bank statement",
    status: !!bankAccount.statementUploaded ? Validity.VALID : Validity.MISSING,
  };
}

function sourceToText(bankAccount: BankAccount) {
  const source = bankAccount.source;
  if (!source) {
    return "-";
  }
  if (source === BankAccountSource.OFFICIAL_REGISTRY) {
    return "Official Registry";
  }
  if (source === BankAccountSource.PSD_LOOKUP) {
    return "Klarna Lookup";
  }
  if (source === BankAccountSource.BANK_STATEMENT) {
    return "Bank statement";
  }
  return "";
}

export function bankgiroWasRejected(bankAccount: BankAccount) {
  if (bankAccount.source !== BankAccountSource.BANKGIRO) {
    return false;
  }

  return !!bankAccount.rejected;
}

function getButton(
  setOpen: (open: boolean) => void,
  contract: Contract,
  bankAccount: BankAccount,
  active: boolean
) {
  let classes = "small event-button";
  let icon = null;
  if (bankAccount.signedOff) {
    icon = (
      <>
        <Visibility />
        <T>View</T>
      </>
    );
  } else if (bankgiroWasRejected(bankAccount)) {
    classes = "small event-button danger-button";
    icon = (
      <>
        <Error />
        <T>Notify merchant</T>
      </>
    );
  } else if (bankAccount.source === BankAccountSource.BANKGIRO) {
    icon = (
      <>
        <Visibility />
        <T>View</T>
      </>
    );
  } else {
    icon = (
      <>
        <Edit />
        <T>Confirm</T>
      </>
    );
  }

  return (
    <Button
      className={classes}
      onClick={() => setOpen(true)}
      status={getButtonStatus(contract, bankAccount, active)}
    >
      {icon}
    </Button>
  );
}

function getButtonStatus(
  contract: Contract,
  bankAccount: BankAccount,
  active: boolean
) {
  if (contract.readOnly) {
    return Status.DISABLED;
  }

  if (!bankAccount.source) {
    return Status.DISABLED;
  }

  const contractStatusAllowsConfirmation =
    contract.status === ContractStatus.MERCHANT_SIGNING ||
    contract.status === ContractStatus.SALES_CONFIRMATION;

  if (!contractStatusAllowsConfirmation) {
    return Status.DISABLED;
  }

  if (!active) {
    return Status.DISABLED;
  }

  if (bankgiroWasRejected(bankAccount)) {
    return Status.DEFAULT;
  }

  if (
    bankAccount.source === BankAccountSource.BANKGIRO &&
    !bankAccount.signedOff
  ) {
    return Status.DISABLED;
  }

  if (bankAccount.source !== BankAccountSource.BANK_STATEMENT) {
    return Status.DEFAULT;
  }

  if (!bankAccount.statementUploaded) {
    return Status.DISABLED;
  }

  return Status.DEFAULT;
}

function getWidth(bankAccount?: BankAccount) {
  if (!bankAccount) {
    return 600;
  }

  if (!!bankAccount.statementUploaded) {
    return 1000;
  }

  if (!!bankAccount.rejected) {
    return 800;
  }

  return 600;
}

export const EventEBank: React.FunctionComponent<Props> = ({
  active,
  contract,
  bankAccount,
  contractId,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const wide = useRef<number>();

  useEffect(() => {
    if (!bankAccount) {
      return;
    }

    wide.current = getWidth(bankAccount);
  }, [bankAccount]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  if (!bankAccount) {
    return (
      <li
        className={cx("timeline-event-document", {
          active,
        })}
        style={{
          height: TIMELINE_BANK_ACCOUNT,
        }}
      >
        <div>
          <div className="truncated">
            <b>
              <T>Bank account</T>,{" "}
            </b>
            <span className="timeline-item-type" />
          </div>
          <div className={cx("additional-event-info", "text-small")}>
            <Beacon mini className="aligned" validity={Validity.MISSING} />
            <span className="additional-event-passive-text">
              <T>Not yet added</T>
            </span>
          </div>
        </div>
      </li>
    );
  }

  const labelAndStatus = getLabelAndStatus(bankAccount);

  return (
    <li
      className={cx("timeline-event-document", {
        active,
      })}
      style={{
        height: TIMELINE_BANK_ACCOUNT,
      }}
    >
      {getButton(setOpen, contract, bankAccount, active)}

      <div>
        <NewOverlay
          width={wide.current}
          open={open}
          onClose={onClose}
          className={cx("overlay-confirm-bank", {
            wide: wide.current,
          })}
        >
          <ConfirmBank
            onClose={onClose}
            contract={contract}
            bankAccount={bankAccount}
            contractId={contractId}
          />
        </NewOverlay>
        <div className="truncated">
          <b>
            <T>Bank account</T>,{" "}
          </b>
          <span className="timeline-item-type">
            {" "}
            <T>{sourceToText(bankAccount)}</T>
          </span>
        </div>
        <div className={cx("additional-event-info", "text-small")}>
          <Beacon mini className="aligned" validity={labelAndStatus.status} />
          <span className="additional-event-passive-text">
            {labelAndStatus.label}
          </span>
        </div>

        {bankAccount.source === BankAccountSource.OFFICIAL_REGISTRY ? null : (
          <div className={cx("additional-event-info", "text-small")}>
            <Beacon
              mini
              className="aligned"
              validity={
                bankAccount.signedOff || bankAccount.rejected
                  ? Validity.VALID
                  : Validity.MISSING
              }
            />
            <span className="additional-event-passive-text">
              {getConfirmationText(bankAccount)}
            </span>
          </div>
        )}
      </div>
    </li>
  );
};
