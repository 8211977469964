import React from "react";
import { Text, StyleSheet, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { getDefaultStyles } from "../PdfStyles";
import { FirstCell } from "./FirstCell";
import { Cell } from "./Cell";
import { ComponentProps } from "../PdfPage";
import { Address } from "./Address";

function getSpecificStyles(show: boolean) {
  return StyleSheet.create({
    store: {
      margin: "0 0 30px 0",
    },
  });
}

function getLabels(t: any) {
  return {
    header: t("Store location"),
    name: t("Store name"),
    phone: t("Store phone number"),
    street: t("Street"),
    postalCode: t("Postal code"),
    city: t("City"),
    country: t("Country"),
  };
}

export const Stores: React.FunctionComponent<ComponentProps> = ({
  show,
  language,
  productType,
  config,
}) => {
  const { t } = useTranslation();
  const labels = getLabels(t);
  const styles = {
    ...getDefaultStyles(show),
    ...getSpecificStyles(show),
  };

  return (
    <View style={styles.store}>
      <View style={styles.sectionHeader}>
        <Text>{labels.header}</Text>
      </View>

      <View style={styles.split}>
        <View style={styles.largeColumn}>
          <FirstCell label={labels.name} show={show}>
            Average store
          </FirstCell>
        </View>
        <View style={styles.smallColumn}>
          <Cell label={labels.phone} show={show}>
            +46 702222222
          </Cell>
        </View>
      </View>

      <Address
        config={config}
        show={show}
        productType={productType}
        language={language}
      />
    </View>
  );
};
