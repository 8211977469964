import React, { RefObject, useCallback, useMemo } from "react";
import cx from "classnames";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { TextInput } from "../form/TextInput";
import { MinLengthValidator } from "../form/validators/MinLengthValidator";
import { MaxLengthValidator } from "../form/validators/MaxLengthValidator";
import { EmailValidator } from "../form/validators/EmailValidator";
import { PhoneCountryCodeValidator } from "../form/validators/PhoneCountryCodeValidator";
import { Associate } from "../../data/models/AssociateTypes";
import { PhoneNumberCountry } from "./PhoneNumberCountry";
import { EmailBackendValidator } from "../form/validators/EmailBackendValidator";
import { Select } from "../form/Select";
import { ACTIVATED_LANGUAGES, Language } from "../../i18n";
import { useTranslation } from "react-i18next";
import { getLanguageName } from "../translation/i18nUtils";
import { RegexValidator, PHONE_REGEX } from "../form/validators/RegexValidator";

export interface ContactRequiredFields {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  phoneNumber: boolean;
}

interface Props {
  associate: Associate;
  onChange: (
    associate: Associate,
    field: string,
    event?: React.ChangeEvent<any>
  ) => void;
  columnClass?: string;
  disabled?: boolean;
  contactRequiredFields: ContactRequiredFields;
  scrollToRef?: RefObject<HTMLElement>;
  showTitle?: boolean;
}

export const MIN_MOBILE_PHONE_CHARS = 8;
export const MAX_MOBILE_PHONE_CHARS = 18;
export const MAX_EMAIL_CHARS = 60;

export const Contact: React.FunctionComponent<Props> = ({
  associate,
  onChange,
  columnClass,
  disabled = false,
  contactRequiredFields,
  scrollToRef,
  showTitle = false,
}) => {
  const { i18n, t } = useTranslation();
  const onLanguageChange = useCallback(
    (language: Language, field: string) => {
      onChange(
        {
          ...associate,
          language,
        },
        field
      );
    },
    [associate, onChange]
  );

  const opts = useMemo(() => {
    const alternatives = Object.values(ACTIVATED_LANGUAGES).map((value) => {
      return {
        value: value,
        text: getLanguageName(value, i18n.language),
      };
    });

    return [
      {
        value: "" as Language,
        text: t("Preferred communication language"),
        disabled: true,
      },
      ...alternatives,
    ];
  }, [i18n.language, t]);

  const onUpdate = useCallback(
    (value: string | undefined, name: string, event: React.ChangeEvent) => {
      const update: any = { ...associate.contact };
      update[name] = value;
      onChange(
        {
          ...associate,
          contact: update,
        },
        name,
        event
      );
    },
    [associate, onChange]
  );

  return (
    <>
      <div className={cx("contact-input", columnClass)}>
        <TextInput
          onChange={onUpdate}
          name="firstName"
          label="First Name"
          value={associate.contact.firstName}
          validators={[
            ...(contactRequiredFields.firstName
              ? [new RequiredValidator("First name is required")]
              : []),
            new MinLengthValidator(
              2,
              "First name must be at least 2 characters"
            ),
            new MaxLengthValidator(
              25,
              "First name must be less than 25 characters"
            ),
          ]}
          disabled={true}
          scrollToRef={scrollToRef}
        />
      </div>
      <div className={cx("contact-input", columnClass)}>
        <TextInput
          onChange={onUpdate}
          name="lastName"
          label="Last Name"
          value={associate.contact.lastName}
          validators={[
            ...(contactRequiredFields.lastName
              ? [new RequiredValidator("Last name is required")]
              : []),
            new MinLengthValidator(
              2,
              "Last name must be at least 2 characters"
            ),
            new MaxLengthValidator(
              25,
              "Last name must be less than 25 characters"
            ),
          ]}
          disabled={true}
          scrollToRef={scrollToRef}
        />
      </div>
      {showTitle && (
        <div className={cx("contact-input", columnClass)}>
          <TextInput
            onChange={onUpdate}
            name="position"
            label="Position"
            value={associate.contact.position}
            hint="Title or position of primary contact"
            validators={[new RequiredValidator("Title is required")]}
            disabled={disabled}
            scrollToRef={scrollToRef}
          />
        </div>
      )}
      <div className={cx("contact-input", columnClass)}>
        <TextInput
          onChange={onUpdate}
          name="email"
          label="Email"
          value={associate.contact.email}
          validators={[
            ...(contactRequiredFields.email
              ? [new RequiredValidator("Email is required")]
              : []),
            new EmailValidator("Email is not valid"),
            new MaxLengthValidator(
              MAX_EMAIL_CHARS,
              t("Email must be less than {{max}} characters", {
                max: MAX_EMAIL_CHARS,
              })
            ),
            new EmailBackendValidator("Email is not valid"),
          ]}
          disabled={disabled}
          scrollToRef={scrollToRef}
        />
      </div>
      <div className={cx("contact-input", columnClass)}>
        <TextInput
          onChange={onUpdate}
          name="phoneNumber"
          label="Mobile phone"
          value={associate.contact.phoneNumber}
          validators={[
            ...(contactRequiredFields.phoneNumber
              ? [new RequiredValidator("Phone is required")]
              : []),
            new PhoneCountryCodeValidator(
              "Phone number must start with a country code e.g +61..."
            ),
            new RegexValidator(
              PHONE_REGEX,
              "Phone contains invalid characters"
            ),
            new MinLengthValidator(
              MIN_MOBILE_PHONE_CHARS,
              `Mobile phone must be at least ${MIN_MOBILE_PHONE_CHARS} characters`
            ),
            new MaxLengthValidator(
              MAX_MOBILE_PHONE_CHARS,
              `Mobile phone must be less than ${MAX_MOBILE_PHONE_CHARS} characters`
            ),
          ]}
          disabled={disabled}
          scrollToRef={scrollToRef}
          message={
            <PhoneNumberCountry phoneNumber={associate.contact.phoneNumber} />
          }
        />
      </div>
      <div className={cx("contact-input", columnClass)}>
        <Select
          onChange={onLanguageChange}
          name="language"
          label="Communication language"
          value={associate.language}
          disabled={disabled}
          alternatives={opts}
        />
      </div>
    </>
  );
};
