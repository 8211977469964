import React, { useState, useCallback } from "react";
import { ContractId } from "../../../../../data/models/CommonTypes";
import {
  BankAccount,
  Contact as ContactInterface,
} from "../../../../../data/models/ContractTypes";
import { T } from "../../../../../components/translation/T";
import { Or } from "../../../../../components/or/Or";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { Status } from "../../../../../data/types";
import { dataBank } from "../../../../../data/dataBank";
import { dataAssociates } from "../../../../../data/dataAssociates";
import { Contact } from "../../../../../components/contact/Contact";
import {
  Associate,
  AssociateRole,
} from "../../../../../data/models/AssociateTypes";
import { Form } from "../../../../../components/form/Form";
import { hasRealErrors } from "../../../../../components/form/FormHelpers";
import { associateQueue } from "../../../../../data/queues/AssociateQueue";
import { defaultAssociate } from "../../../../../state/contractAssociateState";
import { AnimateHeight } from "../../../../../components/animate/AnimateHeight";
import { GenericError } from "../../../../../components/boxes/GenericError";
import { SuccessBox } from "../../../../../components/boxes/SuccessBox";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

interface Props {
  onClose: () => void;
  bankAccount: BankAccount;
  contractId: ContractId;
}

export const BankgiroReject: React.FunctionComponent<Props> = ({
  onClose,
  bankAccount,
  contractId,
}) => {
  const { data: associates } = useSuspenseQuery(
    dataAssociates.fetchAssociates(contractId)
  );
  const [notifyStatus, setNotifyStatus] = useState<Status>(Status.DEFAULT);
  const [addStatus, setAddStatus] = useState<Status>(Status.DEFAULT);
  const [contact, setContact] = useState<ContactInterface>({});
  const queryClient = useQueryClient();

  const notify = useCallback(() => {
    setNotifyStatus(Status.PENDING);
    setAddStatus(Status.DISABLED);
    dataBank
      .notifyRejection(contractId)
      .then(() => {
        setNotifyStatus(Status.SUCCESS);
        queryClient.invalidateQueries({
          queryKey: dataBank.getBankAccountKey(contractId),
        });
      })
      .catch(() => {
        setNotifyStatus(Status.ERROR);
        setTimeout(() => {
          setAddStatus(Status.DEFAULT);
          setNotifyStatus(Status.DEFAULT);
        }, 3000);
      });
  }, [queryClient, contractId]);

  const onAdd = useCallback(() => {
    const currentHolder = associates.find((item) =>
      item.roles.includes(AssociateRole.ACCOUNT_HOLDER)
    );

    if (!currentHolder) {
      return;
    }

    setNotifyStatus(Status.DISABLED);
    setAddStatus(Status.PENDING);

    dataBank
      .notifyRejection(contractId)
      .then(() => {
        return associateQueue
          .saveAssociate(contractId, {
            ...currentHolder,
            roles: currentHolder?.roles.filter(
              (role) => role !== AssociateRole.ACCOUNT_HOLDER
            ),
          })
          .then(() => {
            return associateQueue
              .saveAssociate(contractId, {
                ...defaultAssociate,
                contact,
                roles: [AssociateRole.ACCOUNT_HOLDER],
                id: {},
                pep: {},
                address: {
                  street: "",
                  postalCode: "",
                  city: "",
                  countryCode: "",
                },
              })
              .then(() => {
                setAddStatus(Status.SUCCESS);
                queryClient.invalidateQueries({
                  queryKey: dataBank.getBankAccountKey(contractId),
                });
                queryClient.invalidateQueries({
                  queryKey: dataAssociates.getAssociatesKey(contractId),
                });
              });
          });
      })
      .catch((err) => {
        setAddStatus(Status.ERROR);
        setTimeout(() => {
          setNotifyStatus(Status.DEFAULT);
          setAddStatus(Status.DEFAULT);
        }, 3000);
      });
  }, [associates, contact, queryClient, contractId]);

  const onChange = useCallback((associate: Associate) => {
    setContact({
      ...associate.contact,
    });
  }, []);

  return (
    <div>
      <Form
        name="bankgiro-reject"
        onSubmit={(_, form) => {
          const realErrors = hasRealErrors(form);
          if (!form.isValid) {
            return;
          }
          if (realErrors) {
            return;
          }
          onAdd();
        }}
      >
        <h4>
          <T>Bank account rejected</T>
        </h4>
        <p>
          <T>
            Unfortunately, the proposed account provided by the merchant could
            not be validated by Bankgiro. The merchant will have to provide
            another one or, we could add a new account verifier if we have the
            details to such a person.
          </T>
        </p>

        <Or className="m-top-40 white-bg">
          <div>
            <AnimateHeight name={notifyStatus}>
              <div>
                {notifyStatus === Status.ERROR && <GenericError />}
                {notifyStatus === Status.SUCCESS && (
                  <>
                    <SuccessBox relative>
                      <T>
                        We have notified the account verifier in order for
                        him/her to add another bank account.
                      </T>
                    </SuccessBox>
                    <Button
                      block
                      className="m-top-20"
                      ghost
                      onClick={() => onClose()}
                    >
                      <T>Close</T>
                    </Button>
                  </>
                )}
                {(notifyStatus === Status.DEFAULT ||
                  notifyStatus === Status.DISABLED) && (
                  <Button onClick={notify} block ghost status={notifyStatus}>
                    <T>Notify merchant</T>
                  </Button>
                )}
              </div>
            </AnimateHeight>
          </div>
          <div>
            <b>
              <T>Add a new account verifier</T>
            </b>

            <div className="tablet-columns m-top-30">
              <Contact
                onChange={onChange}
                disabled={addStatus !== Status.DEFAULT}
                associate={
                  {
                    contact,
                  } as Associate
                }
                contactRequiredFields={{
                  email: true,
                  phoneNumber: false,
                  firstName: true,
                  lastName: true,
                }}
                showTitle={true}
              />
            </div>

            <AnimateHeight name={addStatus}>
              <div>
                {addStatus === Status.ERROR && <GenericError />}
                {(addStatus === Status.DEFAULT ||
                  addStatus === Status.DISABLED) && (
                  <Button
                    type="submit"
                    block
                    ghost
                    className="m-top-10"
                    status={addStatus}
                  >
                    <T>Save new account verifier</T>
                  </Button>
                )}
                {addStatus === Status.SUCCESS && (
                  <>
                    <SuccessBox relative>
                      <T>
                        We have saved the new account verifier and will reach
                        out to him/her in order to add a new bank account.
                      </T>
                    </SuccessBox>
                    <Button
                      block
                      className="m-top-20"
                      ghost
                      onClick={() => onClose()}
                    >
                      <T>Close</T>
                    </Button>
                  </>
                )}
              </div>
            </AnimateHeight>

            <div className="m-top-30" />
          </div>
        </Or>
      </Form>
    </div>
  );
};
