import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";
import { RecoilExternalStatePortal } from "./RecoilExternalStatePortal";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/react";
import { APP_ID } from "./network/API";
import { Spinner } from "./components/spinner/Spinner";
import "./i18n";
import "./ui/reset.scss";
import "./ui/typography.scss";
import "./index.scss";
import "react-loading-skeleton/dist/skeleton.css";
import RecoilNexus from "recoil-nexus";
import { STORE_KEY, Store } from "./Store";

Sentry.init({
  dsn: "https://bbd74f8ff2e34178bec1fcf3eaa56f1a@o4504866465185792.ingest.sentry.io/4504876973948928",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.5,
  enabled: process.env.NODE_ENV === "production",
  environment: process.env.REACT_APP_ENV,
});

Sentry.setContext("session", {
  appId: APP_ID,
});

declare global {
  interface Window {
    maps: any;
    d3: any;
    topojson: any;
    cId: () => void;
  }
}

window.maps = window.maps || {
  loaded: {},
};

window.cId = () =>
  console.log(
    "Latest saved correlation id:",
    Store.getValue(STORE_KEY.STORE_CORRELATION_ID)
  );

window.maps.importScript = (url: string) => {
  return new Promise((resolve, reject) => {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.addEventListener("load", () => resolve(script), false);
    script.addEventListener("error", () => reject(script), false);
    document.body.appendChild(script);
  }).catch((err) => {
    console.log("Could not import script ", url, err);
  });
};
const container = document.getElementById("root");
const root = createRoot(container!);

const AppWithCallback = () => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner size="large" />
        </div>
      }
    >
      <Sentry.ErrorBoundary showDialog={false}>
        <RecoilRoot>
          <RecoilNexus />
          <BrowserRouter>
            <App />
          </BrowserRouter>
          <RecoilExternalStatePortal />
        </RecoilRoot>
      </Sentry.ErrorBoundary>
    </Suspense>
  );
};

root.render(<AppWithCallback />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
