import { useState, useCallback, useMemo } from "react";
import {
  Associate,
  AssociateRole,
} from "../../../../data/models/AssociateTypes";
import {
  AssociateTracking,
  BankAccount,
  Contract,
  ContractDocument,
  ContractStatus,
  EcomStore,
  ReviewStatus,
} from "../../../../data/models/ContractTypes";
import { NewOverlay } from "../../../../components/overlay/NewOverlay";
import { getIntlDate, MISSING_DATE } from "../../../../components/utils";
import {
  AssociateId,
  ContractId,
  DocumentId,
} from "../../../../data/models/CommonTypes";
import { ConfirmDoc } from "./ConfirmDocs/ConfirmDoc";
import { EventACreated } from "./TimelineEvents/EventACreated";
import { EventCSentToMerchant } from "./TimelineEvents/EventCSentToMerchant";
import { EventDConfirmedByMerchant } from "./TimelineEvents/EventDConfirmedByMerchant";
import { EventEBank } from "./TimelineEvents/EventEBank";
import { EventFDocument } from "./TimelineEvents/EventFDocument";
import { EventGAssociates } from "./TimelineEvents/EventGAssociates";
import { EventHAssociate } from "./TimelineEvents/EventHAssociate";
import { EventIComplete } from "./TimelineEvents/EventIComplete";
import { IndicatorACreated } from "./TimelineIndicators/IndicatorACreated";
import { IndicatorCSentToMerchant } from "./TimelineIndicators/IndicatorCSentToMerchant";
import { IndicatorDConfirmedByMerchant } from "./TimelineIndicators/IndicatorDConfirmedByMerchant";
import { IndicatorEBank } from "./TimelineIndicators/IndicatorEBank";
import { IndicatorFDocument } from "./TimelineIndicators/IndicatorFDocument";
import { IndicatorGAssociates } from "./TimelineIndicators/IndicatorGAssociates";
import { IndicatorHAssociate } from "./TimelineIndicators/IndicatorHAssociate";
import { IndicatorIComplete } from "./TimelineIndicators/IndicatorIComplete";
import { isAccountHolder } from "../../../../state/contractAssociateState";
import { isSignee } from "../../../../state/contractAssociateState";
import { getMerchantLinks } from "./TimelineEvents/components/MerchantViews";
import "./Timeline.scss";

interface Props {
  reviewStatus: ReviewStatus;
  contract: Contract;
  associates: Associate[];
  documents: ContractDocument[];
  contractId: ContractId;
  bankAccount?: BankAccount;
  primary?: Associate;
  ecomStore?: EcomStore;
}

type TrackingByAssociate = {
  [key: AssociateId]: AssociateTracking;
};

// const MISSING_DATE = " - ";
export const TIMELINE_MERCHANT_LINKS = 25;
export const TIMELINE_START = 14;
export const TIMELINE_EVENT = 90;
export const TIMELINE_CREATED = TIMELINE_EVENT;
export const TIMELINE_SENT_TO_MERCHANT = 90;
export const TIMELINE_CONFIRMED_BY_MERCHANT = 100;
export const TIMELINE_BANK_ACCOUNT = 130;
export const TIMELINE_DOCUMENT = TIMELINE_BANK_ACCOUNT;
export const TIMELINE_ASSOCIATES = 130;
export const TIMELINE_ASSOCIATE = 180;
export const TIMELINE_PRIMARY_REMINDER = 100;

export function allowForPrimaryReminder(
  contract: Contract,
  associates: Associate[]
) {
  const primary = associates.find((associate) =>
    associate.roles.includes(AssociateRole.PRIMARY_CONTACT)
  );

  const isMerchantInput = contract.status === ContractStatus.MERCHANT_INPUT;

  return !!primary && isMerchantInput;
}

export function getTimelineDocsStart(
  contract: Contract,
  associates: Associate[],
  documents: ContractDocument[],
  primaryTracking?: AssociateTracking,
  ecomStore?: EcomStore,
  bankAccount?: BankAccount
) {
  const merchantLinks = getMerchantLinks(
    contract,
    associates,
    documents,
    primaryTracking,
    ecomStore,
    bankAccount
  );
  return (
    TIMELINE_START +
    TIMELINE_CREATED +
    TIMELINE_SENT_TO_MERCHANT +
    merchantLinks.length * TIMELINE_MERCHANT_LINKS +
    (allowForPrimaryReminder(contract, associates)
      ? TIMELINE_PRIMARY_REMINDER
      : 0)
  );
}

export function getTimelineAssociatesStart(
  contract: Contract,
  associates: Associate[],
  documents: ContractDocument[],
  primaryTracking?: AssociateTracking,
  ecomStore?: EcomStore,
  bankAccount?: BankAccount
) {
  return (
    getTimelineDocsStart(
      contract,
      associates,
      documents,
      primaryTracking,
      ecomStore,
      bankAccount
    ) +
    TIMELINE_CONFIRMED_BY_MERCHANT +
    TIMELINE_BANK_ACCOUNT +
    documents.length * TIMELINE_DOCUMENT
  );
}

export function getTimelineComplete(
  contract: Contract,
  documents: ContractDocument[],
  associates: Associate[],
  primaryTracking?: AssociateTracking,
  ecomStore?: EcomStore,
  bankAccount?: BankAccount
) {
  return (
    getTimelineAssociatesStart(
      contract,
      associates,
      documents,
      primaryTracking,
      ecomStore,
      bankAccount
    ) +
    associates.length * TIMELINE_ASSOCIATE +
    TIMELINE_ASSOCIATES
  );
}

export const Timeline = ({
  reviewStatus,
  associates,
  contract,
  documents,
  contractId,
  bankAccount,
  primary,
  ecomStore,
}: Props) => {
  const [confirmGenericDoc, setConfirmGenericDoc] = useState<DocumentId | null>(
    null
  );

  const onClose = useCallback(() => {
    setConfirmGenericDoc(null);
  }, []);

  const { contractStarted, sentToMerchant, merchantConfirmed } = reviewStatus;

  const createdDate = getIntlDate(contractStarted);
  const sentToMerchantDate = getIntlDate(sentToMerchant);
  const confirmedByMerchant = getIntlDate(merchantConfirmed);
  const applicableAssociates = useMemo(
    () =>
      associates.filter(
        (person) => isSignee(person) || isAccountHolder(person)
      ),
    [associates]
  );

  const onConfirmDoc = useCallback((doc: ContractDocument) => {
    setConfirmGenericDoc(doc.documentId);
  }, []);

  const trackingByAssociate: TrackingByAssociate = {};
  reviewStatus.tracking.reduce((acc, cur) => {
    acc[cur.associateId] = cur;
    return acc;
  }, trackingByAssociate);

  return (
    <div className="timeline">
      <NewOverlay
        width={1000}
        open={!!confirmGenericDoc}
        onClose={onClose}
        className="overlay-confirm-doc"
      >
        <ConfirmDoc
          onClose={onClose}
          contractId={contractId}
          document={
            documents.find((v) => v.documentId === confirmGenericDoc) || null
          }
        />
      </NewOverlay>

      <ul className="timeline-tags">
        <EventACreated createdDate={createdDate} />

        <EventCSentToMerchant
          associates={associates}
          sentToMerchantDate={sentToMerchantDate}
          contract={contract}
          primaryTracking={primary && trackingByAssociate[primary.associateId]}
          documents={documents}
          ecomStore={ecomStore}
          bankAccount={bankAccount}
        />
        <EventDConfirmedByMerchant
          confirmedByMerchant={confirmedByMerchant}
          hasMultipleAssociates={associates.length > 1}
        />
        <EventEBank
          active={!!reviewStatus.merchantConfirmed}
          contract={contract}
          bankAccount={bankAccount}
          contractId={contractId}
        />
        {documents.map((doc) => (
          <EventFDocument
            key={doc.documentId}
            document={doc}
            active={confirmedByMerchant !== MISSING_DATE}
            onConfirmDoc={onConfirmDoc}
          />
        ))}

        <EventGAssociates
          confirmedByMerchant={confirmedByMerchant}
          associates={associates}
          contractId={contractId}
          reviewStatus={reviewStatus}
          contract={contract}
        />

        {applicableAssociates.map((person) => (
          <EventHAssociate
            contract={contract}
            contractId={contractId}
            key={person.associateId}
            associate={person}
            bankAccount={bankAccount}
            associateTracking={trackingByAssociate[person.associateId]}
          />
        ))}

        <EventIComplete
          documents={documents}
          associates={applicableAssociates}
          bankAccount={bankAccount}
        />
      </ul>

      <svg className="timeline-sketch">
        <IndicatorACreated />
        <IndicatorEBank
          associates={associates}
          active={confirmedByMerchant !== MISSING_DATE}
          contract={contract}
          primaryTracking={primary && trackingByAssociate[primary.associateId]}
          documents={documents}
          ecomStore={ecomStore}
          bankAccount={bankAccount}
        />

        {documents.map((doc, idx) => (
          <IndicatorFDocument
            associates={associates}
            key={doc.documentId}
            index={idx}
            active={confirmedByMerchant !== MISSING_DATE}
            contract={contract}
            primaryTracking={
              primary && trackingByAssociate[primary.associateId]
            }
            documents={documents}
            ecomStore={ecomStore}
            bankAccount={bankAccount}
          />
        ))}
        {applicableAssociates.map((associate, idx) => (
          <IndicatorHAssociate
            key={associate.associateId}
            confirmedByMerchant={confirmedByMerchant !== MISSING_DATE}
            associate={associate}
            associates={applicableAssociates}
            index={idx}
            contract={contract}
            primaryTracking={
              primary && trackingByAssociate[primary.associateId]
            }
            documents={documents}
            ecomStore={ecomStore}
            bankAccount={bankAccount}
          />
        ))}

        {/**
         * Indicators are reordered since svg has no z-index
         * but instead are layered due to element order
         */}
        <IndicatorDConfirmedByMerchant
          associates={associates}
          active={confirmedByMerchant !== MISSING_DATE}
          contract={contract}
          primaryTracking={primary && trackingByAssociate[primary.associateId]}
          documents={documents}
          ecomStore={ecomStore}
          bankAccount={bankAccount}
        />
        <IndicatorGAssociates
          associates={associates}
          active={confirmedByMerchant !== MISSING_DATE}
          contract={contract}
          primaryTracking={primary && trackingByAssociate[primary.associateId]}
          documents={documents}
          ecomStore={ecomStore}
          bankAccount={bankAccount}
        />
        <IndicatorCSentToMerchant />

        <IndicatorIComplete
          associates={applicableAssociates}
          contract={contract}
          primaryTracking={primary && trackingByAssociate[primary.associateId]}
          documents={documents}
          ecomStore={ecomStore}
          bankAccount={bankAccount}
        />
      </svg>
    </div>
  );
};
