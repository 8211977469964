import React, { useState } from "react";
import {
  BankAccount,
  BankAccountSource,
  Contract,
} from "../../../../../data/models/ContractTypes";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { Associate } from "../../../../../data/models/AssociateTypes";
import { BankConfirmed } from "./BankConfirmed";
import { BankStatementConfirmed } from "./BankStatementConfirmed";
import { T } from "../../../../../components/translation/T";
import { ContractId } from "../../../../../data/models/CommonTypes";
import "./ConfirmBank.scss";
import { BankgiroReject } from "./BankgiroReject";

interface Props {
  contract: Contract;
  onClose: () => void;
  bankAccount: BankAccount;
  contractId: ContractId;
}

export function getName(associate: Associate) {
  if (!associate.contact.firstName && !associate.contact.lastName) {
    return "";
  }

  if (associate.contact.firstName && associate.contact.lastName) {
    return `${associate.contact.firstName} ${associate.contact.lastName}`;
  }

  if (associate.contact.firstName) {
    return associate.contact.firstName;
  }

  return associate.contact.lastName;
}

export const ConfirmBank: React.FunctionComponent<Props> = ({
  onClose,
  bankAccount: account,
  contractId,
}) => {
  const [bankAccount, setBankAccount] = useState<BankAccount>(account);

  if (bankAccount.rejected) {
    return (
      <BankgiroReject
        onClose={onClose}
        bankAccount={bankAccount}
        contractId={contractId}
      />
    );
  }

  // Official registry and Psd lookup will always be confirmed
  if (bankAccount.signedOff) {
    return (
      <BankConfirmed
        onClose={onClose}
        bankAccount={bankAccount}
        contractId={contractId}
      />
    );
  }

  if (bankAccount.source === BankAccountSource.BANKGIRO) {
    return (
      <div className="confirm-bank">
        <strong>
          <T>Bank account</T>
        </strong>

        <p>
          <T>We are awaiting the Bankgiro confirmation.</T>
        </p>

        <Button block ghost onClick={onClose}>
          <T>Close</T>
        </Button>
      </div>
    );
  }

  if (bankAccount.statementUploaded) {
    return (
      <BankStatementConfirmed
        onClose={onClose}
        bankAccount={bankAccount}
        setBankAccount={setBankAccount}
        contractId={contractId}
      />
    );
  }

  return (
    <div className="confirm-bank">
      <strong>
        <T>Bank account</T>
      </strong>

      <p>
        <T>The bank account has not yet been added.</T>
      </p>

      <Button block ghost onClick={onClose}>
        <T>Close</T>
      </Button>
    </div>
  );
};
